import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'
import { clearFix } from 'polished'

const Wrapper = styled.div`
  max-width: 1260px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  @media (min-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${clearFix()}
  ${props =>
    props.smallest &&
    css`
      max-width: 320px;
    `}
  ${props =>
    props.smaller &&
    css`
      max-width: 600px;
    `}
  ${props =>
    props.small &&
    css`
      max-width: 800px;
    `}
  ${props =>
    props.medium &&
    css`
      max-width: 900px;
    `}
  ${props =>
    props.slide &&
    css`
      max-width: 1020px;
    `}

    ${props =>
      props.large &&
      css`
        max-width: 1000px;
      `}
  ${props =>
    props.full &&
    css`
      max-width: 100%;

      @media (min-width: 1200px) {
        padding-left: 3%;
        padding-right: 3%;
      }
    `};

  ${tachyons}
`

export default Wrapper
