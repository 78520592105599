import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { injectGlobal } from 'styled-components'
import { StaticQuery, graphql, withPrefix } from 'gatsby'
import { color, font, padding } from '../../global/constants'
import Header from '../Header'
import Footer from '../Footer'

class Layout extends Component {
  render() {
    const { children, title, description, clean } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                description
                siteUrl
                ogTitle
                ogDescriptionFacebook
                ogImageFacebook
                ogDescriptionTwitter
                ogImageTwitter
                websiteVersion
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
            >
              {/* General tags */}
              <html lang="en" />
              <title>
                {title ? `${title} - Unroll.Me` : data.site.siteMetadata.title}
              </title>
              <meta
                name="description"
                content={
                  description ? description : data.site.siteMetadata.description
                }
              />
              <link
                href="https://fonts.googleapis.com/css?family=PT+Sans:400,700"
                rel="stylesheet"
              />
              {/* Opengraph tags */}
              <meta property="og:locale" content="en_US" />
              <meta property="og:type" content="website" />
              <meta
                property="og:site_name"
                content={data.site.siteMetadata.title}
              />
              <meta
                property="og:url"
                content={data.site.siteMetadata.siteUrl}
              />
              <meta
                property="og:title"
                content={data.site.siteMetadata.ogTitle}
              />
              <meta
                property="og:image"
                content={data.site.siteMetadata.ogImageFacebook}
              />
              <meta property="og:image:type" content="image/jpeg" />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
              <meta property="fb:admins" content="100007830075009" />
              <meta
                property="og:description"
                content={data.site.siteMetadata.ogDescriptionFacebook}
              />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@unrollme" />
              <meta name="twitter:creator" content="@unrollme" />
              <meta
                name="twitter:title"
                content={data.site.siteMetadata.title}
              />
              <meta
                name="twitter:description"
                content={data.site.siteMetadata.ogDescriptionTwitter}
              />
              <meta
                name="twitter:image"
                content={data.site.siteMetadata.ogImageFacebook}
              />
              <meta content="on" httpEquiv="cleartype" />
              <meta httpEquiv="x-ua-compatible" content="ie=edge" />
              <link
                rel="apple-touch-icon-precomposed"
                sizes="144x144"
                href={withPrefix('/favicon/touch-icon-144x144.png')}
              />
              <link
                rel="apple-touch-icon-precomposed"
                sizes="114x114"
                href={withPrefix('/favicon/touch-icon-114x114.png')}
              />
              <link
                rel="apple-touch-icon-precomposed"
                sizes="72x72"
                href={withPrefix('/favicon/touch-icon-72x72.png')}
              />
              <meta name="google-play-app" content="app-id=com.unrollme" />

              <link
                rel="shortcut icon"
                href={withPrefix('/favicon/favicon.ico')}
                type="image/x-icon"
              />
            </Helmet>
            {!clean ? <Header /> : null}
            <main id="main" role="main">
              {children}
              <svg id="svg-image-blur"  display={'none'}>
                  <filter y={"0"} id="blur-effect">
                    <feGaussianBlur stdDeviation="10" />
                  </filter>
              </svg>
            </main>
            {!clean ? <Footer /> : null}
          </>
        )}
      />
    )
  }
}

// eslint-disable-next-line
injectGlobal`
  :root {
    --black: #000;
    --gray: ${color.gray};
    --moon-gray: ${color.grayMoon};
    --light-gray: ${color.grayLight};
    --dark-gray: ${color.grayDark};
    --near-white: ${color.grayLightest};
    --white: ${color.white};
    --blue-unroll: ${color.blueUnroll};

    --spacing-none: 0;
    --spacing-extra-small: ${padding.smallest};
    --spacing-small: ${padding.small};
    --spacing-medium: ${padding.medium};
    --spacing-large: ${padding.large};
  }
  html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
    margin: 0;
    font-family: ${font.family.default};
    font-weight: 400;
    font-style: normal;
  }
  article, aside, footer, header, nav, section { display: block; }
  h1 { font-size: 2em; margin: .67em 0; }
  a {
    color: ${color.blue};
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    text-decoration: underline;
  }
  img { border-style: none; max-width: 100%; width: 100%; }

  html, body, div, article, section, main, footer, header, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li { box-sizing: border-box; }

  [hidden] { display: none; }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
