import React from 'react'
import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'
import { color } from '../../global/constants'
import Section from '../Section'
import Wrapper from '../Wrapper'
import Nav from '../Nav'
import Type from '../Type'
import data from './data'
import { injectIntl, defineMessages } from 'gatsby-plugin-intl'
import PrivacyImage from "../../../static/assets/img/app/privacy-choice.png";


const FullWidthMobileLink = styled.a`
  ${tachyons};
  display: block;
  background: ${color.grayLight};
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;

  @media (min-width: 800px) {
    display: none;
  }
`

const A = styled.a`
  ${tachyons};
`

export const messageDescriptors = defineMessages({
  termsOfService: {
    id: 'components.footer.termsOfService',
    defaultMessage: 'Terms of Service',
  },
  privacyPolicy: {
    id: 'components.footer.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  backToTop: {
    id: 'components.footer.backToTop',
    defaultMessage: 'Back to top',
  },
  allRightsReserved: {
    id: 'components.footer.allRightsReserved',
    defaultMessage: ' Nielsen Consumer LLC. All rights reserved. ',
  },
  dot: {
    id: 'components.footer.dot',
    defaultMessage: '.'
  },
})

const Footer = ({ intl }) => (
  <Section tc medium bgColor={color.grayDarkPage} role="contentinfo" id="footer">
    <Wrapper large>
      <Section small pt0 pt2_ns>
        <Nav
          navData={data}
          placement="Footer"
          textColor="#ffffff"
          fontSize={[16]}
        />
      </Section>
      <Type variant="p" flex flex_column flex_row_l justify_center ph2 white mt0 mt3_ns mb0 lh_copy fontSize={[12]}>
        <Type variant="div">We use your data to fuel our market research business, NielsenIQ. You can manage your data permissions here:&nbsp;</Type>
        <Type variant="div">
          <A
            href="https://support.unroll.me/hc/en-us/requests/new?ticket_form_id=360000640791"
            target="_blank"
          >
            Your Privacy Choices.
          </A>
        </Type>
        <Type variant="div">
          <img style={{height: "15px", width: "auto", marginLeft:"5px"}} alt="privacy" src={PrivacyImage}/>
        </Type>
      </Type>
      <Type variant="p" flex flex_column flex_row_l justify_center ph2 white mt2 mb0 lh_copy fontSize={[12]}>
        <Type variant="div">
          ©{' '}
          {intl.formatDate(new Date(), {
            year: 'numeric',
          })}{' '}
          {messageDescriptors.allRightsReserved.defaultMessage}&nbsp;
        </Type>
        <Type variant="div">
          <Type variant="span">
            <a
              href="/legal/terms"
              target="_blank"
            >
              {intl.formatMessage(messageDescriptors.termsOfService)}
            </a>
            {' & '}
            <a
              href="/legal/privacy"
              target="_blank"
            >
              {intl.formatMessage(messageDescriptors.privacyPolicy)}
            </a>
            {intl.formatMessage(messageDescriptors.dot)}
          </Type>
        </Type>
      </Type>
    </Wrapper>
  </Section>
)

export default injectIntl(Footer)
