// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        }
    }));
} catch (e) {
}

const wheelOpt = supportsPassive ? {passive: false} : false;

// call this to Disable
export const disableScroll = () => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow='hidden';
    // body.style.position = 'fixed';

    // const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    //
    // body.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    // body.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    // body.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    // body.addEventListener('keydown', preventDefaultForScrollKeys, false);

}

// call this to Enable
export const enableScroll = () => {
    const body = document.getElementsByTagName('body')[0];
    body.style.removeProperty('overflow');
    // body.style.removeProperty('position');
    // body.style.overflowY='scroll';
    // const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    //
    // body.removeEventListener('DOMMouseScroll', preventDefault, false);
    // body.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    // body.removeEventListener('touchmove', preventDefault, wheelOpt);
    // body.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}
