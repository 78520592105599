import {defineMessages} from "gatsby-plugin-intl";

const messageDescriptors = defineMessages({
    menu: {
        id: 'components.nav.menu',
        defaultMessage: 'Menu',
    },
    home: {
        id: 'components.nav.home',
        defaultMessage: 'Home',
    },
    app: {
        id: 'components.nav.app',
        defaultMessage: 'App',
    },
    yourData: {
        id: 'components.nav.yourData',
        defaultMessage: 'How we use data',
    },
    login: {
        id: 'components.nav.login',
        defaultMessage: 'Login',
    },
    getStarted: {
        id: 'components.nav.getStarted',
        defaultMessage: 'Get started',
    },
})

export default messageDescriptors;
