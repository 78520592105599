import React, {useState} from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'
import Logo from '../Logo'
import Section from '../Section'
import Nav from '../Nav'
import { color } from '../../global/constants'
import data from './data'
import mobileData from './mobileData'

const StyledLink = styled(props => <Link {...props} />)`
  height:30px;
  line-height:30px;
  @media (min-width: 800px) {
    height: 50px;
    line-height:50px;
  }
  ${tachyons};
`

const Wrapper = styled.div`
  // display: inline-block !important;
  max-width: 1260px;
  margin: auto;
  padding: 35px 45px !important;
  width: 100%;
  height: 100%;
  max-width: 100%;
  @media (max-width: 799px) {
    // display: flex !important;
    padding: 17px 16px !important;
  }
   ${tachyons}
`

const StyledHeader = styled.header`
  background-color: ${color.grayDarkPage};
  
  @media (max-width: 799px) {
        height: 64px !important;
        border-bottom: ${props=> props.open ? 'solid 2px #707070': 'none'}
  }
  
  @media (min-width: 799px) {
        height: 120px !important;
  }
  ${tachyons};
`

const Header = props => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [openEmailPopup, setOpenEmailPopup] = useState(false)

  return (
    <StyledHeader role="banner" id="top" open={openMobileMenu}>
      <Section mb0 pa0 h_100>
        <Wrapper flex full pa0 dn_m dn_l>
              <Nav
                  navData={mobileData}
                  placement="Mobile"
                  textColor="#ffffff"
                  hamburger={true}
                  hamburgerLink="#footer"
                  hamburgerRole="Scroll to footer menu"
                  open={openMobileMenu}
                  setOpen={setOpenMobileMenu}
                  setOpenEmailPopup={setOpenEmailPopup}
                  w_40
              />
              <StyledLink
                  // mt1
                  tc
                  v_mid
                  w_20
                  to="/"
                  alt="Unroll.Me - Home"
                  onClick={()=>{setOpenMobileMenu(false)}}
              >
                <Logo/>
              </StyledLink>
              <Nav
                  navData={data.slice(2)}
                  open={openMobileMenu}
                  setOpen={setOpenMobileMenu}
                  openEmailPopup={openEmailPopup}
                  setOpenEmailPopup={setOpenEmailPopup}
                  placement="Header"
                  textColor="#ffffff"
                  mobile={true}
                  w_40
                  tr
              />
        </Wrapper>
        <Wrapper full dn db_m db_l >
              <StyledLink
                  center
                  to="/"
                  alt="Unroll.Me - Home"
                  fl
              >
                  <Logo v_mid/>
              </StyledLink>
              <Nav
                  navData={data.slice(0, 2)}
                  placement="Header"
                  textColor="#ffffff"
                  fontSize={[16, 18]}
                  open={openMobileMenu}
                  setOpen={setOpenMobileMenu}
                  setOpenEmailPopup={setOpenEmailPopup}
                  fl
                  ml2
              />
              <Nav
                  navData={data.slice(2)}
                  placement="Header"
                  textColor="#ffffff"
                  fontSize={[16, 18]}
                  openEmailPopup={openEmailPopup}
                  setOpenEmailPopup={setOpenEmailPopup}
                  fr
              />
        </Wrapper>
      </Section>
    </StyledHeader>
  );
}

export default Header
