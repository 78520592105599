import Type, {
  Span,
  Title,
  Subtitle,
  P,
  BulletList,
  ListItem,
  A,
  Link,
  Small
} from './Type'

export { Span, Title, Subtitle, P, BulletList, ListItem, A, Link, Small }
export default Type
