const color = {
  green: '#00d9b9',
  red: '#FF7E6C',
  redDark: '#c83c3c',
  yellow: '#FFB628',
  orange: '#f7931d',
  blue: '#41c1c8',
  blueDark: '#00587f',
  blueUnroll: '#00bcbb',
  gray: '#666666',
  grayDark: '#2F2F2F',
  grayLight: '#979797',
  grayMoon: '#e7e7e7',
  grayLightest: '#FAFAFA',
  grayDarkPage: '#404040',
  white: '#FFFFFF'
}

const width = {
  full: '100%',
  huge: '1680px',
  largest: '1440px',
  large: '1280px',
  medium: '960px',
  small: '800px',
  smallest: '580px',
  tiny: '300px',
}

const padding = {
  smallest: '10px',
  small: '20px',
  medium: '40px',
  large: '60px',
}
const margin = padding
const spacing = padding

const button = {
  size: {
    smallest: '0.5em',
    small: '1em',
    medium: '1.5em',
    large: '2em',
    huge: '3em',
  },
}

const font = {
  family: {
    default:
      '"Open Sans", "PT Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  },
  size: {
    tiny: '12px',
    small: '15px',
    default: '16px',
    h1: '3rem',
    h2: '2.4rem',
  },
}

const icon = {
  close:
    'M22.625 15.938l8.541 8.586c0.392 0.361 0.618 0.791 0.678 1.288s-0.060 0.911-0.361 1.243c-1.295 1.657-2.772 3.133-4.428 4.428-0.331 0.301-0.746 0.422-1.243 0.361s-0.941-0.271-1.333-0.633l-8.541-8.586-8.586 8.586c-0.362 0.361-0.791 0.572-1.288 0.633s-0.926-0.060-1.288-0.361c-1.627-1.295-3.088-2.756-4.383-4.383-0.301-0.361-0.422-0.791-0.361-1.288s0.271-0.926 0.633-1.288l8.586-8.586-8.541-8.541c-0.392-0.392-0.618-0.836-0.678-1.333s0.060-0.911 0.361-1.243c1.265-1.627 2.726-3.103 4.383-4.428 0.362-0.301 0.791-0.422 1.288-0.361s0.926 0.286 1.288 0.678l8.586 8.179 8.541-8.179c0.392-0.392 0.836-0.618 1.333-0.678s0.911 0.060 1.243 0.361c1.657 1.295 3.133 2.772 4.428 4.428 0.301 0.331 0.422 0.746 0.361 1.243s-0.286 0.941-0.678 1.333l-8.541 8.541z',
  right:
    'M7.14 30.43L19.1 18.54c1.51-1.544 1.51-3.93 0-5.438L7.14 1.177C5.634-.33 3.214-.33 1.67 1.177.16 2.65.16 5.107 1.67 6.58l9.26 9.223-9.19 9.225c-1.508 1.508-1.508 3.963 0 5.436 1.508 1.508 3.928 1.508 5.4-.035z',
  play:
    'M28.8,0.64 C27.84,0.32 21.92,0 16,0 C10.08,0 4.16,0.32 3.2,0.64 C0.64,1.44 0,7.04 0,12.8 C0,18.56 0.64,24.16 3.2,24.96 C4.16,25.28 10.08,25.6 16,25.6 C21.92,25.6 27.84,25.28 28.8,24.96 C31.36,24.16 32,18.56 32,12.8 C32,7.04 31.36,1.44 28.8,0.64 Z M12.8,20 L12.8,5.6 L22.4,12.8 L12.8,20 Z',
}

export { color, width, padding, spacing, margin, button, font, icon }
