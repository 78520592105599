import React from 'react'
import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import {darken} from 'polished'
import tachyons from 'styled-components-tachyons'
import { A } from '../Type'
import { button, color, font } from '../../global/constants'

const Button = ({ linkBlue, ...props }) => {
  const ButtonType = props.to ? Link : props.href ? A : 'button'

  const ButtonVariant = styled(props => <ButtonType {...props} />)`
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    font-family: inherit;
    cursor: pointer;
    user-select: none;
    border: none;
    text-decoration: none;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    background-image: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      border 0.2s ease-in-out;

    border-radius: 4px;
    font-weight: 600;
    font-size: ${font.size.small};
    padding-left: ${button.size.medium};
    padding-right: ${button.size.medium};
    line-height: calc(${button.size.medium} * 2 + 1px);
    color: ${props.textColor || color.gray};
    background-color: #e7e7e7;
    border: 1px solid #ccc;
    fill: ${color.gray};
    text-align: center;

    &:hover {
      text-decoration: none;
    }

    &:active,
    &:focus {
    }

    ${props =>
      props.link &&
      css`
        border: 0;
        background-color: ${props.bgColor || 'transparent'};
        border-radius: ${props.borderRadius +'px' || '0'};

        &:hover {
          color: ${ props.textColor && darken(0.1, props.textColor || color.gray)};
          background-color: ${ props.bgColor && darken(0.1, props.bgColor || color.white)};
        }

        &.active {
          color: ${color.blue};
          fill: ${color.blue};
        }

        span {
          background-color: ${color.gray};
          transition: background-color 0.2s ease-in-out;
        }
        &:hover span {
          background-color: ${darken(0.2, color.gray)};
        }
      `}

      ${props =>
        linkBlue &&
        css`
          border: 0;
          background-color: transparent;
          border-radius: 0;
          color: ${color.blue};
          fill: ${color.blue};

          &:hover {
            color: ${darken(0.2, color.blue)};
          }
        `}


      ${props =>
        props.primary &&
        css`
          border-color: ${props => (props.link ? 'transparent' : color.green)};
          background-color: ${props =>
            props.link ? 'transparent' : color.green};
          color: ${props => (props.link ? color.green : 'white')};
          fill: white;

          &:hover {
            border-color: ${props =>
              props.link ? 'transparent' : darken(0.1, color.green)};
            background-color: ${props =>
              props.link ? 'transparent' : darken(0.1, color.green)};
            color: ${props =>
              props.link ? darken(0.1, color.green) : 'white'};
          }
        `} ${props =>
    props.tertiary &&
    css`
      border-color: ${props => (props.link ? 'transparent' : color.orange)};
      background-color: ${props => (props.link ? 'transparent' : color.orange)};
      color: ${props => (props.link ? color.orange : 'white')};
      fill: white;

      &:hover {
        border-color: ${props =>
          props.link ? 'transparent' : darken(0.1, color.orange)};
        background-color: ${props =>
          props.link ? 'transparent' : darken(0.1, color.orange)};
        color: ${props => (props.link ? darken(0.1, color.orange) : 'white')};
      }
    `} ${props =>
    props.small &&
    css`
      padding-left: ${button.size.small};
      padding-right: ${button.size.small};
      line-height: calc(${button.size.small} * 2 + 1px);
    `} ${props =>
    props.large &&
    css`
      padding-left: ${button.size.large};
      padding-right: ${button.size.large};
      line-height: calc(${button.size.large} * 2 + 1px);
    `};

    ${props =>
      props.soft &&
      css`
        border-radius: 50px;
      `};
    ${props =>
      props.full &&
      css`
        display: block;
        width: 100%;
      `};
    ${props =>
      props.google &&
      css`
        color: #fff;
        background-color: #d14836;
        border: 1px solid #d14836;
      `};
    ${props =>
      props.yahoo &&
      css`
        color: #fff;
        background-color: #61399d;
        border: 1px solid #61399d;
      `};
    ${props =>
      props.outlook &&
      css`
        color: #fff;
        background-color: #0072c6;
        border: 1px solid #0072c6;
      `};
    ${props =>
        props.signup &&
        css`
          color: #fff;
          background-color: #00bcbb;
          border: 1px solid #00bcbb;
          font-weight: bold;
          width: 155px;
          height: 50px;
          text-align: center;
          vertical-align: middle;
        `};
    ${props =>
      props.video &&
      css`
        color: #fff;
        background-color: rgba(255, 255, 255, 0.2);
        border: 0px;
        width: 173px;
        height: 50px;
      `};
    ${props =>
      props.aol &&
      css`
        color: #fff;
        background-color: #000;
        border: 1px solid #000;
      `};

      ${props =>
        props.secondary &&
        css`
          background-color: ${color.grayMoon};
          border-color: ${color.grayLight};

          &:hover {
            background-color: ${darken(0.1, color.grayMoon)};
          }
        `}

    ${tachyons};
  `
  return <ButtonVariant {...props}>{props.children}</ButtonVariant>
}

export default Button
