import React from 'react'
import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'
import { color } from '../../global/constants'

const Type = props => {
  // Slow fix for the mispelling
  const variant = props.variant || props.variant

  const TypeVariant = styled(variant)`
    text-rendering: optimizeLegibility;
    ${tachyons};
  
    ${props =>
      props.textColor &&
      css`
        color: ${props.textColor || color.blueUnroll};
      `}
    
    ${props =>
      props.fontSize &&

      css`
        font-size: ${`${props.fontSize[0] || 16}px`};
  
        @media (min-width: 450px) and (max-width: 799px) {
          font-size: ${`${props.fontSize[1] || props.fontSize[0] || 16}px`};
        }
  
        @media (min-width: 800px) {
          font-size: ${`${props.fontSize[2] || props.fontSize[1] || props.fontSize[0] || 18}px`};
        }
      `};
  `

  return <TypeVariant {...props}>{props.children}</TypeVariant>
}

const Span = props => {
  return (
    <Type variant="span" {...props}>
      {props.children}
    </Type>
  )
}

const Title = props => {
  return (
    <Type variant="h1" f1 dark_gray mv2 tracked_tight {...props}>
      {props.children}
    </Type>
  )
}

const Subtitle = props => {
  return (
    <Type variant="h2" f4 fw6 dark_gray {...props}>
      {props.children}
    </Type>
  )
}

const P = props => {
  return (
    <Type variant="p" lh_copy dark_gray {...props}>
      {props.children}
    </Type>
  )
}

const BulletList = props => {
  return (
    <Type variant="ul" pl2 dark_gray {...props}>
      {props.children}
    </Type>
  )
}

const ListItem = props => {
  return (
    <Type variant="li" mv1 {...props}>
      {props.children}
    </Type>
  )
}

const A = styled.a`
  font-weight: bold;
  ${tachyons};
`

const Link = A

const Small = props => {
  return (
    <Type variant="p" f5 fw4 gray {...props}>
      {props.children}
    </Type>
  )
}

export { Span, Title, Subtitle, P, BulletList, ListItem, A, Small, Link }
export default Type
