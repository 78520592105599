const data = [
  { name: 'Discover Our App', to: '/app', link: true, small: true },
  {
    name: 'Get Support',
    href: 'https://unrollme.zendesk.com/hc',
    link: true,
    small: true,
    rel: 'noopener',
    type: 'external',
  },
  {
    name: 'Contact Us',
    href:
      'https://support.unroll.me/hc/en-us/requests/new?ticket_form_id=360000177691',
    link: true,
    small: true,
    rel: 'noopener',
    type: 'external',
  },
  {
    name: 'Data Usage',
    to: '/your-data',
    link: true,
    small: true,
  }
]
export default data
