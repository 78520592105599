import React, {useCallback, useEffect} from 'react'
import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'
import Type from '../Type'
import {injectIntl} from 'gatsby-plugin-intl'
import {useMatchMedia} from "../../hooks/UseMatchMedia";
import {disableScroll, enableScroll} from "./scrollingUtil";
import Navigation from "./Navigation";
import NavLink from "./NavLink";
import messageDescriptors from './messageDescriptors'
import {EnterEmailPopUp} from "./EnterEmailPopUp";

const BorderDiv = styled.div`
  position: relative;
  width: calc(100vw) !important;
  height: 1px !important;
  opacity: 1 !important;
  background: rgb(64, 64, 64);
  z-index:100;
  : after{
    content: "";
    background: #707070;
    position: absolute;
    bottom: 0;
    left: 16px;
    height: 1px;
    width: calc(100vw - 32px);
  }
`

const ScrolledContainerWrapper = styled.div`
  position:absolute;
  display: ${props => (props.open ? "block" : "none")};

  width: 100vw;
  left:-16px;
  height:328px;
  overflow-y:scroll;
  z-index:100;
`

const ScrolledContainer=styled.div`
  overflow-y:scroll;
  height: calc(100vh - 64px);
  max-height:328px;
`

const BackgroundContainer=styled.div`
  position:absolute;
  display: ${props => (props.open ? "block" : "none")};
  width: 100vw;
  left:-16px;
  height:100vh;
  z-index: 90;
`

const SignupButton = styled(Type)`
  color: #fff;
  background-color: #00bcbb !important;
  border: 1px solid #00bcbb;
  font-weight: bold;
  width: 155px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50px;
  display: none !important;
  
  @media  (min-width: 800px) {
      display: inline-block !important;
  }

  ${tachyons};
`

const Toggle = styled.div`
  position:relative !important;
  margin-bottom: 16px;
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
  ${tachyons};
`

const Hamburger = styled.div`
  background-color: white;
  width: 20px !important;
  height: 2px !important;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? " translate(-3px, 7px) rotate(45deg)" : "inherit")};
  ::after {
    width: 20px;
    height: 2px;
    background-color: white;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }
  ::after {
    transform: ${props =>
    props.open ? "rotate(-90deg) translate(10px, 0px)" : "rotate(0deg)"};
    top: 10px;
    left: 0;
  }
`

const getSignupLink = (signupBtnData) => {
  const navigatorAgent = typeof navigator !== 'undefined' ? navigator.userAgent : ''
  const isIphone = /iPhone OS/.test(navigatorAgent)
  const isAndroid = /Android/.test(navigatorAgent)

  if (isIphone) {
    return 'https://bnc.lt/IPig/83sUGgL57M'
  }
  if (isAndroid) {
    return 'https://bnc.lt/IPig/p0NveowSjH'
  }

  return typeof window !== 'undefined'
    ? signupBtnData.href + window.location.search
    : '';
}

const Nav = props => {
  const{open, setOpen, openEmailPopup, setOpenEmailPopup} = props;

  const {isMobile, isTablet, isDesktop } = useMatchMedia();

  const { intl } = props
  const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
    messages[key] = intl.formatMessage(messageDescriptors[key])
    return messages
  }, {})
  const signupBtnData = props.navData.find(button => button.messageRef === 'getStarted')

  const createNavLinks=useCallback(()=>{
    return props.navData.map((button, index) => {
      let slug = button.name.toLowerCase().replace(/ /g, '-')
      let type = button.to ? 'link' : 'button'
      let to = button.to && typeof window !== 'undefined' ? button.to + window.location.search : null
      let href = button.href && typeof window !== 'undefined' ? button.href + window.location.search : null
      return (
          <>
            {!isDesktop && open && props.placement === 'Mobile' && index!==0 ?
                <BorderDiv/> : null}
            <NavLink
                key={button.name+props.placement}
                onClick={() => handleOpenClick(false, button)}
                style={button.name === "Log In" ? {position: "relative"} : {}}
                placement={props.placement}
                textColor={props.textColor}
                to={to}
                href={button.name === 'Sign Up' ? getSignupLink(button) : button.name === 'Log In' ? "" : href}
                link={button.link}
                rel={button.rel}
                alt={button.name}
                className={[slug, type].join(' ')}
                soft={button.soft}
                primary={button.primary}
                small={button.small}
                tertiary={button.tertiary}
                activeClassName="active"
                exact={true}
                open={open}
                b
                dn
                dib_l
                dib_m
            >
              {button.name === 'Sign Up'
                  ? (
                      <SignupButton
                          variant="span"
                          f5_ns
                          dn
                          dib_l
                          dib_m
                      >
                        Sign Up For Free
                      </SignupButton>)
                  : <>
                  <Type variant="div" fontSize={props.fontSize}>{button.name}</Type>
                  </>
              }
            </NavLink>
          </>
      )
  })}, [isDesktop, open]);

  useEffect(
      () => {
        if(isDesktop && open){
          handleOpenClick(false);
        }
      }, [isDesktop, open])

  function handleOpenClick(open, button) {
    if (button?.name === "Log In") {
      setOpenEmailPopup(true);
    }

    if (props.hamburger ) {
      document.getElementById('main').style.filter = open ? 'url(#blur-effect)' : '';
    }
    if (open) {
      disableScroll();
    } else {
      enableScroll();
    }

    if (setOpen) {
      setOpen(open);
    }
  }

  return (
    <Navigation role="navigation" {...props} cf>
       {props.hamburger ? (
           <Toggle
               onClick={() => handleOpenClick(!open)}
           >
             <Hamburger open={open} />
           </Toggle>
      ) : null}

      {props.hamburger && <>
        <ScrolledContainerWrapper open={open}><ScrolledContainer> {createNavLinks()} </ScrolledContainer></ScrolledContainerWrapper>
        <BackgroundContainer open={open} onClick={(e) =>{e.preventDefault(); handleOpenClick(false)}} />
      </>}

      {!props.hamburger &&  createNavLinks() }
      {openEmailPopup && <EnterEmailPopUp show={openEmailPopup} setShow={setOpenEmailPopup}/>}

      { !open && signupBtnData && props.placement === 'Header'
        ? <NavLink
            key={`${signupBtnData.name}-${props.placement}-mobile`}
            placement={props.placement}
            textColor={props.textColor}
            to={signupBtnData.to && typeof window !== 'undefined' ? signupBtnData.to + window.location.search : null}
            href={getSignupLink(signupBtnData)}
            link={signupBtnData.link}
            rel={signupBtnData.rel}
            alt={signupBtnData.name}
            className={[signupBtnData.name.toLowerCase().replace(/ /g, '-'), 'button'].join(' ')}
            soft={signupBtnData.soft}
            primary={signupBtnData.primary}
            small={signupBtnData.small}
            tertiary={signupBtnData.tertiary}
            activeClassName="active"
            fontSize={props.fontSize}
            open={open}
            exact
            b
            f5
            dn_l
            dn_m
            dib
          >
          {signupBtnData.name}
        </NavLink>
        : null
      }
      {/*{*/}
      {/*  props.hamburger && props.open && CSS.supports('backdrop-filter', 'blur(10px)') ?*/}
      {/*    <BlurDiv/> : null*/}
      {/*}*/}
      {/*{*/}
      {/*  props.hamburger && props.open &&*/}
      {/*    <svg id="svg-image-blur">*/}
      {/*      <filter id="blur-effect">*/}
      {/*        <feGaussianBlur stdDeviation="10" />*/}
      {/*      </filter>*/}
      {/*    </svg>*/}
      {/*}*/}
    </Navigation>
  )
}
export default injectIntl(Nav)
