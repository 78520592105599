import React from 'react'
import styled, {css} from "styled-components";
import {font} from "../../global/constants";
import {darken} from "polished";
import tachyons from "styled-components-tachyons";

const Navigation = styled.nav`
  ${props => {
    switch (props.placement) {
        case 'Footer':
            return css`
          display: flex;
          flex-direction: column;
          font-size: ${font.size.small};
          &:hover {
            color: ${darken(0.2, '#848484')};
          }
        
          @media (min-width: 450px) and (max-width: 799px) {
            display: block !important;
            width: 100%;
            padding: 10px !important;
          }
    
          @media (min-width: 800px) {
            display: inline-block;
            &.hamburger {
              display: none !important;
            }
          }
          ${tachyons};
        `

        case 'Mobile':
            return css`
          @media (max-width: 799px) {  
              // display: block !important;
              // font-size: ${font.size.small};
              &:hover {
                color: ${darken(0.2, '#848484')};
              }
              height: 0 !important;
              position: relative;
              top: 10px !important;
              left: 0px !important;
              background: black;
              z-index:100
              margin-left: 0 !important;
              width: 40% !important;
          }
          ${tachyons};
        `
        case 'Header':
            return css`
          @media (max-width: 799px) {
            // display: block !important;
            // width: 40% !important;
            // text-align: right !important;
          }
          ${tachyons};
        `
        default:
            return css`
          
          ${tachyons};
          `
    }
}}`

export default Navigation;
