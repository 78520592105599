const data = [
  {
    name: 'Discover Our App',
    to: '/app',
    link: true,
    dataTrack: 'Marketing Header Button Clicked',
    messageRef: 'app',
  },
  {
    name: 'Get Support',
    href: 'https://unrollme.zendesk.com/hc',
    link: true,
    small: true,
    rel: 'noopener',
    type: 'external',
  },
  {
    name: 'Log In',
    href: '/a/login',
    link: true,
    dataTrack: 'Marketing Header Button Clicked',
    messageRef: 'login',
  },
  {
    name: 'Sign Up',
    href: '/a/signup',
    link: true,
    dataTrack: 'Marketing Header Button Clicked',
    messageRef: 'getStarted',
  }
]

export default data
