import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'
import { padding } from '../../global/constants'

// Display Inline Block to fix collapsing margins
const Section = styled.div`
  display: inline-block;
  width: 100%;

  ${props =>
    props.small &&
    css`
      padding-top: ${padding.small};
      padding-bottom: ${padding.small};
    `} ${props =>
  props.medium &&
  css`
    padding-top: ${padding.medium};
    padding-bottom: ${padding.medium};
  `} ${props =>
  props.large &&
  css`
    padding-top: ${padding.medium};
    padding-bottom: ${padding.medium};

    @media only screen and (min-width: 500px) {
      padding-top: ${padding.large};
      padding-bottom: ${padding.large};
    }
  `}
  ${props =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor || '#ffffff'};
    `}

  ${tachyons};
`

export default Section
