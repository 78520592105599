import styled, {css} from "styled-components";
import Button from "../Button";
import {button, color, font} from "../../global/constants";
import {darken} from "polished";
import tachyons from "styled-components-tachyons";

const NavLink = styled(Button)`
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
  white-space: nowrap;
  padding-left: ${button.size.small};
  padding-right: ${button.size.small};

  ${props =>
    props.placement === 'Header' &&
    css`
      font-size: ${font.size.default};
      line-height: 30px !important;
      color: #848484;
      font-weight: bold;

      &:hover {
        color: ${darken(0.2, color.white)};
      }

      @media (min-width: 450px) {
        &.get-started {
          display: inline-block;
          padding-left: 5px;
          color: #4ab9ba;
        }
      }

      @media (min-width: 450px) and (max-width: 799px) {
        line-height: 18px
        &.hamburger {
          padding-left: 1rem;
        }
        &.get-started {
          padding-right: 1rem;
        }
        &.sign-up  {
          display: inline-block !important;
          padding: 0px 15px !important;
          
        }
      }

      @media (min-width: 800px) {
        display: inline-block;
        line-height: 50px !important;

        &.hamburger {
          display: none !important;
        }
      }
    `
  };

  ${props =>
    props.placement === 'Footer' &&
    css`
      font-size: ${font.size.small};
      &:hover {
        color: ${darken(0.2, '#848484')};
      }

      @media (max-width: 450px) {
        display: block !important;
        width: 100%;
        padding-top: 10px !important;
      }

      @media (min-width: 450px) and (max-width: 799px) {
        display: block !important;
        padding-top: 10px !important;
      }

      @media (min-width: 800px) {
        padding: 0px 40px !important;
      }
    `};
    
    ${props =>
    props.placement === 'Mobile' &&
    css`
      font-size: ${font.size.small};
      display: block !important;
      padding: 20px 16px !important;
      margin: 0!important;
      width: calc(100vw);
      text-align: left !important;
      position: relative !important;
      background: #404040 !important;
      color: white;
      z-index:100;
      align-items: left;
      flex-direction: column;
      justify-content: flex-start;
      transition: all 0.5s ease-in;
      border-radius:0px !important;
      transition-timing-function: cubic-bezier(10,2,3,1);
      top: 0px; 
      left: ${props.open ? '0px !important' : 'calc(-100vw) !important' };
      &:hover {
        color: ${darken(0.2, '#848484')};
      }
      
      > span {
        width: calc(100%);
        display: block !important;
        line-height: 50px;
      }
      
      @media  (max-width: 799px) {
        line-height: 18px !important;
      }
    `};

  ${tachyons};
`

export default NavLink;
