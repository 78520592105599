import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  position: absolute;
  width: 300px;
  height: 238px;
  background: #fff;
  border-radius: 10px;
  transform: translate(-100px, 10px);
  z-index: 100;
`

const Title = styled.div`
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
`

const EmailInput = styled.input`
  margin-top: 15px;
  width: 275px;
  height: 34px;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #ced4da;

  &.error {
    border-color: #dc3545;

    &:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25);
    }
  }

  &:focus {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
    outline: 0;
  }
`

const LoginButton = styled.button`
  height: 40px;
  width: 275px;
  font-weight: 600;
  border-radius: 20px;
  margin-top: 15px;
  background: #00bcbb;
  color: #fff;
  border: none;
  cursor: pointer;
`

const LinkWrapper = styled.a`
  color: #00bcbb;
  text-decoration: none;

  &:hover {
    color: #00cccb;
    text-decoration: none;
  }
`

const PrivacyWrapper = styled.div`
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  font-size: 10px;
  line-height: 143%;
  color: #404040;
`

const ErrorMessage = styled.div`
  color: #dc3545;
  text-align: center;
  font-size: 13px;
`;

const Arrow = styled.div`
  position: absolute;
  top: -11px;
  background: white;
  width: 26px;
  height: 26px;
  transform: rotate(45deg);
`

export const EnterEmailPopUp = ({show, setShow}) => {
    const ref = useRef(null);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError('');
    };


    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.match(emailRegex)) {
            setError('Please enter a valid email address');
            return false;
        }
        return true
    };

    const hidePopup= (event)=>{
        if(ref.current && !ref.current.innerHTML.includes(event.target.innerHTML)){
           setShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener("click", hidePopup);
        window?.addEventListener('resize', ()=>setShow(false));
        return () => {
            document.removeEventListener("click", hidePopup);
            window?.removeEventListener("resize", ()=>setShow(false));
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        const valid = validateEmail();
        if (valid) {
            window.location = `/app/inbox?selected=${e.target.email.value}`
        }
    }

    if(!show){
        return null;
    }

    return (
        <Wrapper innerRef={(r)=>{ ref.current=r}} >
            <Arrow/>
            <Title>
                Connect your mailbox:
            </Title>
            <form onSubmit={handleSubmit} style={{width: 275}}>
                <EmailInput value={email} placeholder="Enter email"
                            // type="email"
                            name="email"
                            onChange={handleEmailChange}
                            className={!error ? "" : "error"}/>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <LoginButton type="submit">Log In</LoginButton>
            </form>
            <PrivacyWrapper style={{marginTop: '20px'}}>
                By connecting your mailbox with Unroll.Me, you understand that you may
                be enrolled in our{' '}
                <LinkWrapper target="_blank" href="/your-data">
                    measurement panel
                </LinkWrapper>
                , and you are agreeing to our{' '}
                <LinkWrapper target="_blank" href="/legal/terms">
                    Terms of Service
                </LinkWrapper>{' '}
                and{' '}
                <LinkWrapper target="_blank" href="/legal/privacy">
                    Privacy Policy
                </LinkWrapper>
                .
            </PrivacyWrapper>
        </Wrapper>
    )
}
