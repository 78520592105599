import { useState, useLayoutEffect } from "react";

const queries = [
    "(max-width: 449px)",
    "(min-width: 450px) and (max-width: 767px)",
    "(min-width: 768px)"
];

export const useMatchMedia = () => {
    const mediaQueryLists = queries.map((query) => typeof window !=='undefined' && window.matchMedia(query));

    const getValues = () => mediaQueryLists.map((list) => list.matches);

    const [values, setValues] = useState(getValues);

    useLayoutEffect(() => {
        const handler = () => setValues(getValues);

        mediaQueryLists.forEach((list) => list.addEventListener("change", handler));

        return () =>
            mediaQueryLists.forEach((list) =>
                list.removeEventListener("change", handler)
            );
    }, []);

    return ["isMobile", "isTablet", "isDesktop"].reduce(
        (acc, screen, index) => ({
            ...acc,
            [screen]: values[index]
        }),
        {}
    );
};
